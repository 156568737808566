<template>
  <section id="team">
    <div class="container">
      <div class="row" :class="(data.bannerExist) ? 'pt-3' : 'padding-top-no-banner'">
        <div class="col-12">
          <h2><span :class="(data.global['headerIcons']) ? 'pr-3' : 'pr-0'"><b-icon v-if="data.global['headerIcons']" icon="shield-check" class="text-primary" aria-hidden="true"></b-icon></span>{{ data.json.title }} </h2>
        </div>
        <div class="col-12" v-for="(member, index) in data.json['members']" :key="index">
          <div class="row">
            <div :class="member.responsive['img']">
              <img :src="`${publicPath}assets/${data.portfolioName}/${member.img['fileName']}`" :alt="member.img['alt']" :title="member.img['title']" class="img-fluid rounded">
            </div>
            <div :class="member.responsive['paragraph1']">
              <h2> {{ member['name'] }}</h2>
              <h3> {{ member['title'] }}</h3>
              <h4> {{ member['subtitle'] }}</h4>
              <p style="margin-top:-8px; font-size: 0.9em;">{{ member['qualification'] }}</p>
              <p v-html="member['paragraph1']"></p>
            </div>
            <div class="col-12 pt-3">
              <p v-html="member['paragraph2']"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Team',
  data () {
    return {
      publicPath: process.env.VUE_APP_BASE_URL
    }
  },
  computed: {
    data () {
      const cJson = this.$webData.components.find((e) => e.name === 'Team');
      const portfolioName = this.$portfolioName;
      return {
        json: cJson,
        global: this.$webData.global,
        portfolioName: portfolioName,
        bannerExist: (this.$webData.components.find((e) => e.name === 'Banner') !== undefined)
      }
    }
  },
  mounted() {
    //console.log(this.bannerExist)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .padding-top-no-banner {
    padding-top: 80px !important;
  }
</style>
